import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  Renderer2,
  ViewChildren,
  QueryList
} from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { SectionContainerDirective } from '../../directives/section-container.directive';
import { CarouselComponent } from 'angular-bootstrap-md';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit, AfterViewInit {
  @Input() options: any = [
    { image: '../../../assets/our-services/bg-img-1.jpg', active: true },
    { image: '../../../assets/our-services/bg-img-2.jpg', active: false },
    { image: '../../../assets/our-services/bg-img-3.jpg', active: false }
  ];

  @ViewChild('stickyMenu', { static: true }) menuElement: ElementRef;
  @ViewChild(CarouselComponent, { static: true }) carousel: CarouselComponent;
  @ViewChildren(SectionContainerDirective) sections: QueryList<
    SectionContainerDirective
  >;
  @ViewChild('landingSection', { static: true }) landing: ElementRef;

  sticky: boolean = false;
  elementPosition: any;
  activeSectionId = '';
  pageWidth = 0;

  private sectionsData: Array<any>;
  private subscription: Subscription;

  constructor(public renderer: Renderer2) {}

  ngOnInit() {
    const timer$ = interval(6000);

    this.subscription = timer$.subscribe(sec => {
      this.calculateNext();
    });

    window['SmoothParallax'].init({
      basePercentageOn: 'pageScroll'
    });

    if(window.innerWidth < 500) {
      this.options.forEach((img, i) => img.image = `../../../assets/our-services/bg-img-mobile-${i+1}.jpg`)
    } else {
      this.options.forEach((img, i) => img.image = `../../../assets/our-services/bg-img-${i+1}.jpg`)
    }

    window.addEventListener('resize', () => {
      if(window.innerWidth < 500) {
        this.options.forEach((img, i) => img.image = `../../../assets/our-services/bg-img-mobile-${i+1}.jpg`)
      } else {
        this.options.forEach((img, i) => img.image = `../../../assets/our-services/bg-img-${i+1}.jpg`)
      }
    });
  }

  ngAfterViewInit() {
    this.elementPosition = this.menuElement.nativeElement.offsetTop;
    this.renderer.setStyle(
      this.menuElement.nativeElement.parentElement,
      'height',
      `${this.menuElement.nativeElement.clientHeight}px`
    );

    this.calculateSectionsInfo();
  }

  private calculateSectionsInfo() {
    this.sectionsData = this.sections
      .map(s => {
        let obj = { directive: s, position: s.el.nativeElement.offsetTop };
        return obj;
      })
      .sort((a, b) => b.position - a.position);
  }

  calculateNext() {
    let currentIndex = this.options.findIndex(x => x.active);
    if (currentIndex === this.options.length - 1) {
      this.options[0].active = true;
    } else {
      this.options[currentIndex + 1].active = true;
    }
    this.options[currentIndex].active = false;
  }

  nextVideo() {
    this.carousel.nextSlide();
  }

  getLandingSize() {
    return this.landing.nativeElement.offsetHeight;
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const lastSection = this.sections.find(
      s => s.el.nativeElement.id === 'branding-section'
    );
    if (
      this.sectionsData[0].position != lastSection.el.nativeElement.offsetTop
    ) {
      this.calculateSectionsInfo();
    }

    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.elementPosition) {
      this.sticky = true;
    } else {
      this.sticky = false;
    }

    let index = this.sectionsData.findIndex(
      element => windowScroll + 15 >= element.position
    );
    if (index != -1) {
      this.activeSectionId = this.sectionsData[
        index
      ].directive.el.nativeElement.id;
    } else {
      this.activeSectionId = '';
    }

    const formSection = document.querySelector(
      '#last-section-services'
    ) as HTMLElement;

    let oldSticky = this.sticky;
    
    if (
      windowScroll >= formSection.offsetTop - formSection.offsetHeight / 4 &&
      window.innerWidth <= 768
    ) {
      this.sticky = false;
    } else {
      this.sticky = oldSticky;
    }
  }
}
