import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  elementPosition: any;
  whitePanel = false;
  landingHeight;
  
  constructor(private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0)
    });
  }

  onActivate(componentRef){
    this.landingHeight = componentRef.getLandingSize();
  }

  ngAfterViewInit() {
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {

    const windowScroll = window.pageYOffset;
    if (windowScroll >= this.landingHeight) {
      this.whitePanel = true;
    } else {
      this.whitePanel = false;
    }

  }
}
