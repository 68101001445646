import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-custom-carousel',
  templateUrl: './custom-carousel.component.html',
  styleUrls: ['./custom-carousel.component.scss']
})
export class CustomCarouselComponent implements OnInit {
  @Input() options = [{ id:1, image: '../../../../assets/team/jennifer_home.jpg'},
  { id:2, image: '../../../../assets/team/matt_home.jpg'}];

  @Input() indexSelected: number = 1;

  constructor() { 
    
  }

  ngOnInit() {
  }
}
