import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-mobile-text-expander',
  templateUrl: './mobile-text-expander.component.html',
  styleUrls: ['./mobile-text-expander.component.scss']
})
export class MobileTextExpanderComponent implements OnInit {
  // Leaving this for future reference if they decide to return it just mobile
    // --- isMobile = this.deviceService.isMobile();
  isMobile = true;
  isCompressed = false;

  constructor(private deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
    // Leaving this for future reference if they decide to return it just mobile
      // --- this.isMobile = this.deviceService.isMobile();
    this.isMobile = true;
    this.isCompressed = this.isMobile;
  }

}
