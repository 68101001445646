import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DeviceDetectorModule } from 'ngx-device-detector';
//import { MatInputModule, MatButtonModule, MatSelectModule, MatIconModule } from '@angular/material';

import { MatProgressBarModule } from '@angular/material';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './components/home/home.component';
import { OverlayContentComponent } from './shared/components/overlay-content/overlay-content.component';
import { CustomCarouselComponent } from './shared/components/custom-carousel/custom-carousel.component';
import { CarouselContentComponent } from './shared/components/carousel-content/carousel-content.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LayoutWithHeaderComponent } from './shared/components/layout-with-header/layout-with-header.component';
import { ContactComponent } from './components/contact/contact.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { OurServicesComponent } from './components/our-services/our-services.component';
import { ContactFormComponent } from './shared/components/contact-form/contact-form.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { SectionContainerDirective } from './directives/section-container.directive';
import { WavesModule, ModalModule, CarouselModule  } from 'angular-bootstrap-md'
import { HomeSectionComponent } from './components/home/home-section/home-section.component';
import { MobileTextExpanderComponent } from './shared/components/mobile-text-expander/mobile-text-expander.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    // MatInputModule, 
    // MatButtonModule,
    // MatSelectModule,
    // MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatProgressBarModule,
    WavesModule.forRoot(), 
    ModalModule.forRoot(), 
    CarouselModule.forRoot(),
    DeviceDetectorModule.forRoot(),
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  declarations: [
    AppComponent,
    HomeComponent,
    OverlayContentComponent,
    CustomCarouselComponent,
    CarouselContentComponent,
    FooterComponent,
    LayoutWithHeaderComponent,
    ContactComponent,
    AboutUsComponent,
    OurServicesComponent,
    ContactFormComponent,
    PricingComponent,
    SectionContainerDirective,
    HomeSectionComponent,
    MobileTextExpanderComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
