import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-section',
  templateUrl: './home-section.component.html',
  styleUrls: ['./home-section.component.scss']
})
export class HomeSectionComponent implements OnInit {

  constructor() { }
  @Input() title: string;
  @Input() subtitle: string;
  @Input() content: string;
  @Input() buttonName: string;
  @Input() buttonUrl: string;
  @Input() buttonUrlFragment: string;
  @Input() bgImg: string;
  @Input() deviceOutline: string;
  @Input() videoUrl: string;
  @Input() videoImgUrl: string;
  @Input() customClass: string;
  
  ngOnInit() {
  }

}
