import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  FormArray,
  FormControl
} from "@angular/forms";
import { environment } from '../../../environments/environment';
import * as emailjs from 'emailjs-com';

@Component({
  selector: "app-pricing",
  templateUrl: "./pricing.component.html",
  styleUrls: ["./pricing.component.scss"]
})
export class PricingComponent implements OnInit {
  contactForm: FormGroup;
  helpOptions;
  @ViewChild('basicModal', { static: true }) basicModal: any;
  @ViewChild('landingSection', { static: true }) menuElement: ElementRef;
  
  constructor(private formBuilder: FormBuilder) {
    this.contactForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      userEmail: ["", [Validators.email]],
      phone: ["", Validators.required],
      practice: ["", Validators.required],
      message: [""],
      helpOptions: new FormArray([]),
      website: [""],
      emailRecipients: [environment.emailRecipients]
    });

    this.helpOptions = [
      { id: 1, name: "All Services" },
      { id: 2, name: "Web Design" },
      { id: 3, name: "Social Media" },
      { id: 4, name: "Social Media Ads [SMM]" },
      { id: 5, name: "SEO" },
      { id: 6, name: "PPC [Adwords]" },
      { id: 7, name: "Branding" },
      { id: 8, name: "Photography" },
      { id: 10, name: "Videography" },
      { id: 11, name: "Reputation Management" }
    ];

    this.addCheckboxes();
    emailjs.init('user_HveyXI7414q3k8ypSIL4A');
  }

  ngOnInit() {
    window['__ctm'].main.runNow();
    window['objectFitPolyfill']();
    this.formData.controls.forEach((check, i) => {
      check.setValue(false);
    });
  }

  get formData() {
    return <FormArray>this.contactForm.get("helpOptions");
  }

  contact() {
    const options = this.contactForm.value.helpOptions
      .map((v, i) => (v ? this.helpOptions[i].name : null))
      .filter(v => v !== null).join();
    const data = {options,...this.contactForm.value};
    emailjs.send('gmail', 'pricing_form', data)
    .then(response => {
      // trigger this method once you have the form data captured within you web form.  This means you have to capture the form submission events and data.
      window['__ctm'].form.track('app.calltrackingmetrics.com', // the capture host
      'FRT472ABB2C5B9B141A76E8CA78D4A0D7FA59923C45CB0DE0641683CFD28575AEBC', // this FormReactor
      '4694106620',
      {
        country_code: "1", // the expected country code e.g. +1, +44, +55, +61, etc... the plus is excluded
        name: `${this.contactForm.value['firstName']} ${this.contactForm.value['lastName']}`,
        phone: this.contactForm.value['phone'],
        email: this.contactForm.value['userEmail'],
        custom: {
          practice: this.contactForm.value['practice'],
          message: this.contactForm.value['message'],
          website: this.contactForm.value['website'],
          helpNeeded: options
        }
      });

      this.basicModal.show();
    }).catch(error => {
      console.error(error);
    });
  }

  getLandingSize() {
    return this.menuElement.nativeElement.offsetHeight;
  }

  private addCheckboxes() {
    this.helpOptions.map((o, i) => {
      const control = new FormControl(i === 0); // if first item set to true, else false
      (this.contactForm.controls.helpOptions as FormArray).push(control);
    });
  }

  checkPoint(event, id) {
    const status =  this.formData.controls[0].value;
    id !== 0 ? this.formData.controls[0].setValue(false) : id === 0 ? this.formData.controls.forEach((check) => status ? check.setValue(true) : check.setValue(false)) : '';
  }

  private minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const totalSelected = formArray.controls
        .map(control => control.value)
        .reduce((prev, next) => (next ? prev + next : prev), 0);

      return totalSelected >= min ? null : { required: true };
    };

    return validator;
  }
}
