import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ngModuleJitUrl } from '@angular/compiler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  brandTypeSelected = 2;
  brandTypes = [
    {
      id: 1,
      image: '../../../../assets/brand-types/logo.png',
      icon: '../../../../assets/icons/logo.png',
      name: 'Logo'
    },
    {
      id: 2,
      image: '../../../../assets/brand-types/brochure.png',
      icon: '../../../../assets/icons/brochure.png',
      name: 'Brochure'
    },
    {
      id: 3,
      image: '../../../../assets/brand-types/card.png',
      icon: '../../../../assets/icons/card.png',
      name: 'Card'
    },
    {
      id: 4,
      image: '../../../../assets/brand-types/letterhead.png',
      icon: '../../../../assets/icons/letterhead.png',
      name: 'Letterhead'
    },
    {
      id: 5,
      image: '../../../../assets/brand-types/folder.png',
      icon: '../../../../assets/icons/folder.png',
      name: 'Folder'
    },
    {
      id: 6,
      image: '../../../../assets/brand-types/booklet.png',
      icon: '../../../../assets/icons/booklet.png',
      name: 'Booklet'
    }
  ];
  sliderAutoPlay;
  sliderTimer;

  @ViewChild('landingSection', { static: true }) menuElement: ElementRef;

  constructor() {}

  ngOnInit() {
    let brandingScroll = 0;
    let playing = false;
    document.addEventListener('scroll', e => {
      brandingScroll =
        document.querySelector('.branding-container').getBoundingClientRect()
          .top -
        document.querySelector('.branding-container').clientHeight / 2;
      if (brandingScroll < 100 && brandingScroll > -1200 && !playing) {
        playing = true;
        setTimeout(() => {
          document.querySelectorAll('.icon')[1].classList.add('active');
          this.sliderAutoPlay = () => {
            let next = document.querySelector('.active').nextElementSibling;
            document
              .querySelectorAll('.icon.clickeable')
              .forEach(clickable => clickable.classList.remove('active'));
            next === null
              ? (next = document.querySelector('.icon.clickeable'))
              : '';
            next.classList.contains('clickeable')
              ? next.classList.add('active')
              : next.parentElement.classList.add('active');
            this.brandTypeSelected = Number(next.id);
          };
          this.sliderTimer = setInterval(this.sliderAutoPlay, 6000);
        }, 0);
      }
    });

    window['objectFitPolyfill']();
  }

  ngOnDestroy() {
    clearInterval(this.sliderTimer);
  }

  getLandingSize() {
    return this.menuElement.nativeElement.offsetHeight;
  }

  selectBrandType(event, id: number) {
    clearInterval(this.sliderTimer);
    this.sliderTimer = setInterval(this.sliderAutoPlay, 6000);
    document.querySelectorAll('.icon.clickeable').forEach(clickable => {
      clickable.classList.remove('active');
    });

    if (event.target.classList.contains('clickeable')) {
      event.target.classList.add('active');
    } else {
      event.target.parentElement.classList.add('active');
    }
    this.brandTypeSelected = id;
  }
}
