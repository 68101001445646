import { Component, OnInit, Input, ElementRef, ViewChild } from "@angular/core";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.scss"]
})
export class AboutUsComponent implements OnInit {
  @Input() persons: any = [
    {
      id: 1,
      displayText: "Matt Griffin",
      jobTitle: "CEO",
      image: "../../../assets/team/matt_about.jpg",
      active: false,
      class: "ceo",
      text:
        "Matt is one of the original founders of Six-20. A true businessman at heart. Matt started selling at the early age of 11. He’s built many businesses from the ground up " +
        "for over 22 years. Matt’s favorite part about owning a business was the marketing piece which is why Six-20 was evolved. Running a business while helping others grow their " +
        "business is a win-win. One of Matt’s philosophies in life is “it’s all about the story.” His goal is to help doctors tell their story as founders and experts in the dental implant field."
    },
    {
      id: 2,
      displayText: "Brittany Dalke",
      jobTitle: "COO",
      image: "../../../assets/team/brittany_about.jpg",
      active: false,
      class: "coo",
      text:
        "Brittany was recruited to be our Chief Operations Officer for several reasons. She has worked on all sides of the dental industry since 2007. She is the" +
        " perfect fit to work alongside our specialty clients. Starting as a Sterilization tech, she quickly received her RDA (still current) and then moved to the front" +
        " office where she served as a treatment coordinator. After 2 years, she was promoted to office manager where she helped grow the practice to over $3MM a year in collections."
    },
    {
      id: 3,
      displayText: "Dan Murtaugh",
      jobTitle: "Director of Media Productions",
      image: "../../../assets/team/dan_about.jpg",
      active: false,
      class: "dmp",
      text:
        "Dan is our Director of Media Productions. As one of our leading artists on the team, he is responsible for producing our dental implant focused video " +
        "and photography. Originally from Arizona, Dan was a teacher of art at the collegiate level and has brought this artistic skill to Six-20 to produce award-winning" +
        " material to help facilitate telling each and every unique story of each practice, and the clinicians that lead their teams."
    },
    {
      id: 4,
      displayText: "Jennifer Griffin",
      jobTitle: "Director of Social Branding",
      image: "../../../assets/team/jennifer_about.jpg",
      active: false,
      class: "dsb",
      text:
        "Jennifer is our Director of Social Branding. During her MBA program at the University of North Texas, Jen wanted to be ahead of the social trend" +
        "and focus on the effects of proper storytelling through social media platforms. Her focus during her post grad, as well as her application for the last 10" +
        " years in multiple industries, including dental implants, has made Jen on of the leading experts on the effects of Social Branding."
    }
  ];
  @ViewChild('landingSection', { static: true }) menuElement: ElementRef;

  constructor() {}

  ngOnInit() {}

  getLandingSize() {
    return this.menuElement.nativeElement.offsetHeight;
  }

  setActive(item: any) {
    let temp = this.persons.find(x => x.active);
    if (temp != null) {
      temp.active = false;
    }
    item.active = true;
  }

  setInactive(item: any) {
    item.active = !item.active;
  }
}
