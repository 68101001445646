import { Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appSectionContainer]'
})
export class SectionContainerDirective {

  constructor(public el: ElementRef) { }

}
