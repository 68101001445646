import { Component, OnInit, Input } from "@angular/core";
import { interval, Subscription } from "rxjs";
import { OnDestroy } from "@angular/core";

@Component({
  selector: "app-carousel-content",
  templateUrl: "./carousel-content.component.html",
  styleUrls: ["./carousel-content.component.scss"]
})
export class CarouselContentComponent implements OnInit {
  @Input() title = "Our Team";
  classSelected = "";
  @Input() options: any = [
    {
      displayText: "Matt Griffin",
      jobTitle: "CEO",
      image: "../../../../assets/team/matt_home.jpg",
      active: true
    },
    {
      displayText: "Brittany Dalke",
      jobTitle: "COO",
      image: "../../../../assets/team/brittany_home.jpg",
      active: false
    },
    {
      displayText: "Dan Murtaugh",
      jobTitle: "Director of Media Productions",
      image: "../../../../assets/team/dan_home.jpg",
      active: false
    },
    {
      displayText: "Jennifer Griffin",
      jobTitle: "Director of Social Branding",
      image: "../../../../assets/team/jennifer_home.jpg",
      active: false
    }
  ];
  progress = 0;
  @Input() seconds: number = 25;
  private subscription: Subscription;

  constructor() {}

  ngOnInit() {
    const timer$ = interval(250);
    const duration = this.seconds * 4;

    this.subscription = timer$.subscribe(sec => {
      // this.progressbarValue = 100 - sec * 100 / seconds;
      // this.curSec = sec;
      this.progress++;

      if (this.progress === duration) {
        this.progress = 0;
        this.calculateNext();
      }
    });
  }

  calculateNext() {
    let currentIndex = this.options.findIndex(x => x.active);
    if (currentIndex === this.options.length - 1) {
      this.options[0].active = true;
    } else {
      this.options[currentIndex + 1].active = true;
    }
    this.options[currentIndex].active = false;
  }

  optionSelected(item: any) {
    this.options.find(x => x.active).active = false;
    item.active = !item.active;
    this.classSelected = item.class;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.subscription.unsubscribe();
  }
}
