import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay-content',
  templateUrl: './overlay-content.component.html',
  styleUrls: ['./overlay-content.component.scss']
})
export class OverlayContentComponent implements OnInit {
  show: boolean = false;

  constructor() {}

  ngOnInit() {}

  toggleNav() {
    const header = document.querySelector('.header') as HTMLElement;
    this.show = !this.show;


    if (this.show === true) {
      header.style.zIndex = '11';
    }
    if (this.show === false) {
      header.style.zIndex = '9';
    }
  }
}
