import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
//import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { LayoutWithHeaderComponent } from './shared/components/layout-with-header/layout-with-header.component';
import { ContactComponent } from './components/contact/contact.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { OurServicesComponent } from './components/our-services/our-services.component';

const routerOptions: ExtraOptions = {
  useHash: false,
  anchorScrolling: 'enabled',
  onSameUrlNavigation: "reload",
  // ...any other options you'd like to use
};

const routes: Routes = [
    { path: '', component: HomeComponent},
    { path: 'contact', component: ContactComponent},
    { path: 'pricing', component: PricingComponent},
    { path: 'about-us', component: AboutUsComponent},
    { path: 'our-services', component: OurServicesComponent}
];

@NgModule({
  declarations: [],
  imports: [ RouterModule.forRoot(routes, routerOptions) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
