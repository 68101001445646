import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window['__ctm'].main.runNow();
  }

  getLandingSize() {
    // returning 0 because this page doesn't have landing section and the header panel should be show from the beginning of the page
    return 0;
  }

}
