import { Component, OnInit, Input, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import * as emailjs from 'emailjs-com';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  @Input() title: string = '';
  @ViewChild('basicModal', { static: true }) basicModal: any;

  constructor(
    private formBuilder: FormBuilder,
  ) { 
    emailjs.init('user_HveyXI7414q3k8ypSIL4A');
  }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      userEmail: ['', [Validators.required, Validators.minLength(6), Validators.email]],
      message: ['', [Validators.required, Validators.minLength(6)]],
      phone: ["", Validators.required],
      emailRecipients: [environment.emailRecipients]
    });
  }

  contact() {
    emailjs.send('gmail', 'contactform', this.contactForm.value)
    .then(response => {
      window['__ctm'].form.track('app.calltrackingmetrics.com', // the capture host
      'FRT472ABB2C5B9B141A76E8CA78D4A0D7FAE626E1CCF9AB9DBE5207ED31A56990EC', // this FormReactor
      '4694106620',
      {
        country_code: "1", // the expected country code e.g. +1, +44, +55, +61, etc... the plus is excluded
        name: `${this.contactForm.value['firstName']} ${this.contactForm.value['lastName']}`,
        phone: this.contactForm.value['phone'],
        email: this.contactForm.value['userEmail'],
        custom: {
          message: this.contactForm.value['message'],
        }
      });
      this.basicModal.show();
    }).catch(error => {
      console.error(error);
    });
  }

}
